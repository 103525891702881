import { call, put, takeLatest, } from 'redux-saga/effects'
import { Urls } from 'src/common/urls'
import * as Actions from './action'
import * as ActionTypes from './actionTypes'
import { NetworkingProvider } from 'src/features/Security/networking/networkingProvider'
import { GetSaleRecordsFilters } from 'src/common/models/sale'
import { ReportView } from 'src/common/models/report'
import { GetSalePaymentRecordsFilters } from 'src/common/models/salePayment'

function* createSaleApi(action: Actions.CreateSaleAction) {
    try {
        const response = yield call(NetworkingProvider.getInstance().getControlInterface().post, Urls.BACKEND.SALE.CREATE, action.sale)
        yield put(Actions.createSaleSuccess(response.data))
    } catch (err) {
        yield put(Actions.createSaleError(err));
    }
}

const buildQueryString = (filters?: GetSaleRecordsFilters) => {
    const filtersList: string[] = []
    if (filters?.dateFrom && filters.dateTo) {
        filtersList.push(`dateFrom=${filters.dateFrom}&dateTo=${filters.dateTo}`)
    }
    if (filters?.paymentStatusId) {
        filtersList.push(`paymentStatus=${filters.paymentStatusId}`)
    }
    if (filters?.view) {
        filtersList.push(`view=${filters.view}`)
    }

    return filtersList.length > 0
        ? `?${filtersList.join('&')}`
        : ``
}

function* getSalesRecordsApi(action: Actions.GetSaleRecordsAction) {
    try {
        const response = yield call(NetworkingProvider.getInstance().getControlInterface().get, Urls.BACKEND.SALE.GET_RECORDS(buildQueryString(action.filters)))
        yield put(Actions.getSaleRecordsSuccess(response.data))
    } catch (err) {
        yield put(Actions.getSaleRecordsError(err));
    }
}

function* getSalesByIdApi(action: Actions.GetSaleByIdAction) {
    try {
        const response = yield call(NetworkingProvider.getInstance().getControlInterface().get, Urls.BACKEND.SALE.GET_BY_ID(action.saleId))
        yield put(Actions.getSaleByIdSuccess(response.data))
    } catch (err) {
        yield put(Actions.getSaleByIdError(err));
    }
}

function* certifySaleApi(action: Actions.CertifySaleAction) {
    try {
        const response = yield call(NetworkingProvider.getInstance().getControlInterface().post, Urls.BACKEND.SALE.CERTIFY_SALE, action.sale)
        yield put(Actions.certifySaleSuccess(response.data))
    } catch (err) {
        yield put(Actions.certifySaleError(err));
    }
}

function* getSalesReportApi(action: Actions.GetSalesReportAction) {
    try {
        const response = yield call(NetworkingProvider.getInstance().getControlInterface().get, Urls.BACKEND.SALE.GET_REPORT(buildQueryString(action.filters)))
        yield put(Actions.getSalesReportSuccess(response.data, action.filters?.view as ReportView))
    } catch (err) {
        yield put(Actions.getSalesReportError(err));
    }
}

function* createSalePaymentApi(action: Actions.CreateSalePaymentAction) {
    try {
        const response = yield call(NetworkingProvider.getInstance().getControlInterface().post, Urls.BACKEND.SALE_PAYMENT.CREATE, action.paymentData)
        yield put(Actions.createSalePaymentSuccess(response.data))
    } catch (err) {
        yield put(Actions.createSalePaymentError(err));
    }
}

const buildSalePaymentsQueryString = (filters?: GetSalePaymentRecordsFilters) => {
    const filtersList: string[] = []
    if (filters?.dateFrom && filters.dateTo) {
        filtersList.push(`dateFrom=${filters.dateFrom}&dateTo=${filters.dateTo}`)
    }
    if (filters?.saleId) {
        filtersList.push(`saleId=${filters.saleId}`)
    }
    if (filters?.customerId) {
        filtersList.push(`customerId=${filters.customerId}`)
    }

    return filtersList.length > 0
        ? `?${filtersList.join('&')}`
        : ``
}

function* getPurchasePaymentsApi(action: Actions.GetSalePaymentRecordsAction) {
    try {
        const response = yield call(NetworkingProvider.getInstance().getControlInterface().get, Urls.BACKEND.SALE_PAYMENT.GET_RECORDS(buildSalePaymentsQueryString(action.filters)))
        yield put(Actions.getSalePaymentRecordsSuccess(response.data))
    } catch (err) {
        yield put(Actions.getSalePaymentRecordsError(err));
    }
}

export default function* saleSagas() {
    yield takeLatest(ActionTypes.CREATE_SALE_API, createSaleApi)
    yield takeLatest(ActionTypes.GET_SALE_RECORDS_API, getSalesRecordsApi)
    yield takeLatest(ActionTypes.GET_SALE_BY_ID_API, getSalesByIdApi)
    yield takeLatest(ActionTypes.CERTIFY_SALE_API, certifySaleApi)
    yield takeLatest(ActionTypes.GET_SALES_REPORT_API, getSalesReportApi)
    yield takeLatest(ActionTypes.CREATE_SALE_PAYMENT_API, createSalePaymentApi)
    yield takeLatest(ActionTypes.GET_SALE_PAYMENTS_API, getPurchasePaymentsApi)
}