import { Modal, Spin } from "antd"
import { SystemDescriptions } from "src/common/descriptions"

type Props = {
    isVisible: boolean
    title?: string
    description?: string
}

export const Loader = (props: Props) => {

    const descriptions = SystemDescriptions.COMPONENTS.LOADER

    return (
        <Modal
            open={props.isVisible}
            closable={false}
            footer={null}
            zIndex={1100}
            centered
        >
            <h4>{props.title || descriptions.TITLE} &nbsp; <Spin size="large" /></h4>

            <p>{props.description || descriptions.DESCRIPTION}</p>
        </Modal>
    )
}
