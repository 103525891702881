import { PageCard } from "src/common/styles/styles"
import { SearchableTable } from "src/components/SearchableTable"
import { TABLE_COLUMNS } from "./config"
import { UnitsSoldReport } from "../UnitsSold/types"
import { Company } from "src/common/models/company"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "src/state/reducer"
import { serviceLoading } from "src/common/apiLoader/state/selection"
import { GET_UNITS_SOLD_REPORT_API } from "../../state/actionTypes"
import { useState } from "react"
import { DateRanges } from "src/components/SearchableTable/types"
import moment from "moment"
import dayjs from 'dayjs'
import { SystemConstants } from "src/common/constants"
import { getUnitsSoldReporttApi } from "../../state/action"
import { SystemDescriptions } from "src/common/descriptions"
import { Dropdown, MenuProps } from "antd"
import { XlsExporter } from "src/common/XlsExporter"
import { PrinterOutlined, FileExcelOutlined } from '@ant-design/icons'
import UnitSoldReport from "../UnitsSold/UnitsSoldReport"

interface ReduxProps {
    isLoadingData: boolean
    report?: UnitsSoldReport[]
    company?: Company
}

export const UnitsSoldUtility = () => {
    const dispatch = useDispatch()

    const reduxProps: ReduxProps = useSelector((state: RootState) => ({
        isLoadingData: serviceLoading(state, [GET_UNITS_SOLD_REPORT_API]),
        report: state.report.unitsSoldReport,
        company: state.common.company,
    }))

    const [dateRanges, setDateRanges] = useState<DateRanges>({
        startDate: moment().startOf('month').format(SystemConstants.DATETIME_QUERY(true)),
        endDate: moment().format(SystemConstants.DATETIME_QUERY(false)),
    })

    const descriptions = SystemDescriptions.PAGES.REPORTS.UNITS_SOLD

    const buildReportData = () => {
        return reduxProps?.report?.map((reportItem: UnitsSoldReport, idx: number) => {
            return {
                ID: idx,
                PRODUCT_ID: reportItem.productId,
                MEASSURE: reportItem.meassure,
                DESCRIPTION: reportItem.product,
                QTY: reportItem.qty,
                AVG_PRICE: reportItem.averagePrice,
                AVG_COSTE: reportItem.averageCoste,
            }
        }) ?? []
    }

    const handleMenuClick: MenuProps['onClick'] = (e) => {
        if (e.key === "EXPORT_EXCEL") {
            const exporter = new XlsExporter({
                sheetPrefixName: "Report",
                documentName: "UnitsSoldUtilityReport",
                appendTimestamp: true,
            })

            exporter.exportData(buildReportData())
        }
    };

    const items: MenuProps['items'] = [
        {
            label: descriptions.EXPORT_DATA,
            key: 'EXPORT_EXCEL',
            icon: <FileExcelOutlined rev={undefined} />,
        },
    ];

    const menuProps = {
        items,
        onClick: handleMenuClick,
    };

    const handleButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        if (!reduxProps.report || !reduxProps.company) {
            return
        }

        UnitSoldReport({
            data: reduxProps.report,
            dateFrom: dateRanges.startDate,
            dateTo: dateRanges.endDate,
            company: reduxProps.company,
            type: "UTILITY",
        })
    };

    const renderExtraContent = () => {
        return <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                gap: 12,
            }}
        >
            <Dropdown.Button
                size="small"
                menu={menuProps}
                onClick={handleButtonClick}
                disabled={!reduxProps.report || reduxProps.report.length === 0}
            >
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', gap: '8px' }} >
                    {<PrinterOutlined rev={undefined} />} {descriptions.PRINT}
                </div>
            </Dropdown.Button>
        </div>
    }

    const handleDateRangesChange = async (dateRanges: DateRanges) => {
        setDateRanges({
            startDate: moment(dateRanges.startDate).format(SystemConstants.DATETIME_QUERY(true)),
            endDate: moment(dateRanges.endDate).format(SystemConstants.DATETIME_QUERY(false)),
        })
    }

    const handleActionButtonClick = () => {
        dispatch(getUnitsSoldReporttApi({
            dateFrom: dateRanges.startDate,
            dateTo: dateRanges.endDate,
        }))
    }

    const renderContent = () => (
        <PageCard
            size="small"
            title={descriptions.TITLE_UTILITY}
            extra={renderExtraContent()}
        >
            <SearchableTable
                showSearch
                searchLabel={descriptions.TABLE.SEARCH_PLACEHOLDER}
                items={reduxProps.report ?? []}
                tableColumns={TABLE_COLUMNS}
                showLoader={reduxProps.isLoadingData}
                showRangePicker
                rangePickerOnChange={handleDateRangesChange}
                rangePickerDefaultValues={[dayjs().startOf('month'), dayjs()]}
                showActionButton
                actionButtonLabel={descriptions.TABLE.ACTION_BUTTON}
                actionButtonOnClick={handleActionButtonClick}
            />
        </PageCard >
    )

    return (
        renderContent()
    )
}
