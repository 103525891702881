export interface PaymentTransaction {
    targetId: number // PURHASE OR SALE ID
    type: string
    amount: number
    amountPayed: number
    currentBalance: number
    target: string // PURCHASE, SALE
    targetDocument: string
    targetExternalId: number // SUPPLIER OR CUSTOMER
    targetExternalName: string // supplier or customer name
    username: string
    userId: number
}

export enum TabTypes {
    CASH = 'CASH',
    TRANS = 'TRANS',
    CREDIT_NOTE = 'CREDITE_NOTE',
}