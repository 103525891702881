import { Alert, Col, DatePicker, Form, Input, Row, Tabs } from "antd"
import { SystemConstants } from "src/common/constants"
import { Col12, Col6 } from "src/components/Columns"
import { CurrencyInput } from "src/components/CurrencyInput"
import { FormActionButtons } from "src/components/FormActionButtons"
import { PaymentTransaction, TabTypes } from "./types"
import { amountValidator, cleanCurrencyValue, formatToCurrency } from "src/common/util"
import { useEffect, useState } from "react"
import { TransactionPayment } from "src/common/models/transactionPayment"
import { ProductTransactionType } from "src/components/ProductTransactionModal/types"
import { SystemDescriptions } from "src/common/descriptions"
import { LabelWithButton } from "src/components/LabelWithButton"
import dayjs from 'dayjs'

interface Props {
    onFinish: (paymentData: TransactionPayment) => void
    onCancel: () => void
    initialValues?: any
    readonly?: boolean
    currentData: PaymentTransaction
    errorMessage?: string
    type?: ProductTransactionType
}

export const PaymentForm = (props: Props) => {

    const [form] = Form.useForm();

    const [paymentAmount, setPaymentAmount] = useState<number>(0)
    const [alertMessage, setAlertMessage] = useState<string>("")
    const [selectedTab, setSelectedTab] = useState<TabTypes>(TabTypes.CASH)

    const descriptions = SystemDescriptions.COMPONENTS.MODAL_PAYMENT

    useEffect(() => {
        form.setFieldsValue({
            paymentAmount: formatToCurrency("0"),
            paymentNewBalance: formatToCurrency(props.currentData.currentBalance.toString()),
        })
    }, [])

    useEffect(() => {
        const newBalance: number = props.currentData.currentBalance - paymentAmount

        form.setFieldsValue({
            paymentAmount: formatToCurrency(paymentAmount.toString()),
            paymentNewBalance: formatToCurrency(newBalance.toString())
        })

        if (paymentAmount > props.currentData.currentBalance) {
            setAlertMessage(descriptions.ERRORS.PAYMENT_MUST_BE_LOWER_EQUAL_BALANCE)
        } else if (paymentAmount === 0) {
            setAlertMessage(descriptions.ERRORS.PAYMENT_MUST_BE_NOT_ZERO)
        } else if (paymentAmount === props.currentData.currentBalance) {
            setAlertMessage("")
        } else {
            setAlertMessage(descriptions.ERRORS.PAYMENT_MUST_BE_LOWER_EQUAL_BALANCE)
        }
    }, [paymentAmount])

    const onFinish = (formValues) => {
        const data: TransactionPayment = {

            document: props.currentData.targetDocument,
            amount: Number(cleanCurrencyValue(formValues.paymentAmount)),
            amountPending: Number(cleanCurrencyValue(formValues.paymentNewBalance)),
            cashAmount: Number(formValues.cashAmount || 0),
            cashDate: formValues.cashDate?.format(SystemConstants.DATETIME_DATABASE_FORMAT) || '',
            cashRef: formValues.cashRef || '',
            transAmount: Number(formValues.bankAmount || 0),
            transDate: formValues.bankDate?.format(SystemConstants.DATETIME_DATABASE_FORMAT) || '',
            transBank: formValues.bankBank || '',
            transRef: formValues.bankRef || '',
            creditNoteAmount: Number(formValues.creditNoteAmount || 0),
            creditNoteDate: formValues.creditNoteDate?.format(SystemConstants.DATETIME_DATABASE_FORMAT) || '',
            creaditNoteRef: formValues.creaditNoteRef || '',
            comment: '',
            createdBy: props.currentData.username,
            createdAt: "",
            userId: props.currentData.userId,

            purchaseId: props.type === ProductTransactionType.PURCHASE ? props.currentData.targetId : undefined,
            supplierId: props.type === ProductTransactionType.PURCHASE ? props.currentData.targetExternalId : undefined,
            supplier: props.type === ProductTransactionType.PURCHASE ? props.currentData.targetExternalName : undefined,

            saleId: props.type === ProductTransactionType.SALE ? props.currentData.targetId : undefined,
            customerId: props.type === ProductTransactionType.SALE ? props.currentData.targetExternalId : undefined,
            customer: props.type === ProductTransactionType.SALE ? props.currentData.targetExternalName : undefined,
        }

        props.onFinish(data)
    }

    const getButtonStatus = (): boolean => {
        const {
            cashAmount, cashDate, cashRef,
            bankAmount, bankDate, bankBank, bankRef,
            creditNoteAmount, creditNoteDate, creditNoteRef,
        } = form.getFieldsValue()

        let isCashboxInvalid: boolean = false
        let isBankInvalid: boolean = false
        let isCreditNoteInvalid: boolean = false

        if (Number(cashAmount) > 0) {
            if (!cashDate || !cashRef) {
                isCashboxInvalid = true
            }
        }
        if (Number(bankAmount) > 0) {
            if (!bankDate || !bankBank || !bankRef) {
                isBankInvalid = true
            }
        }
        if (Number(creditNoteAmount) > 0) {
            if (!creditNoteDate || !creditNoteRef) {
                isCashboxInvalid = true
            }
        }

        return isCashboxInvalid || isBankInvalid || isCreditNoteInvalid || paymentAmount === 0 || paymentAmount > props.currentData.currentBalance
    }

    const renderActionButtons = () => (
        <FormActionButtons
            cancelText={descriptions.CANCEL}
            actionText={descriptions.ACCEPT}
            actionDisabled={getButtonStatus}
            onCancel={props.onCancel}
        />
    )

    const renderData = () => (
        <Row gutter={10}>
            <Col span={6} >
                <Form.Item
                    label={props.type === ProductTransactionType.PURCHASE
                        ? descriptions.DATA.TARGET_PURCHASE
                        : descriptions.DATA.TARGET_SALE
                    }
                >
                    <Input
                        placeholder={""}
                        value={props.currentData.target}
                        readOnly
                        size="small"
                    />
                </Form.Item>
            </Col>

            <Col span={6} >
                <Form.Item
                    label={props.type === ProductTransactionType.PURCHASE
                        ? descriptions.DATA.TOTAL_PURCHASE
                        : descriptions.DATA.TOTAL_SALE
                    }
                >
                    <CurrencyInput
                        value={formatToCurrency(props.currentData.amount.toString())}
                        placeholder={"0"}
                        readonly
                        size={"small"}
                    />
                </Form.Item>
            </Col>

            <Col span={6} >
                <Form.Item
                    label={descriptions.DATA.CURRENT_PAYED}
                >
                    <CurrencyInput
                        value={formatToCurrency(props.currentData.amountPayed.toString())}
                        placeholder={"0"}
                        readonly
                        size={"small"}
                    />
                </Form.Item>
            </Col>

            <Col span={6} >
                <Form.Item
                    label={descriptions.DATA.CURRENT_BALANCE}
                >
                    <CurrencyInput
                        value={formatToCurrency(props.currentData.currentBalance.toString())}
                        placeholder={"0"}
                        readonly
                        size={"small"}
                    />
                </Form.Item>
            </Col>
        </Row>
    )

    const renderTotals = () => (
        <Row gutter={10}>
            <Col span={6}>
            </Col>

            <Col span={6}>
            </Col>

            <Col span={6} >
                <Form.Item
                    label={descriptions.FORM.PAYMENT_AMOUNT.LABEL}
                    name={"paymentAmount"}
                >
                    <CurrencyInput
                        placeholder={"0"}
                        readOnly
                        size={"small"}
                    />
                </Form.Item>
            </Col>

            <Col span={6} >
                <Form.Item
                    label={descriptions.FORM.NEW_BALANCE.LABEL}
                    name={"paymentNewBalance"}
                >
                    <CurrencyInput
                        placeholder={"0"}
                        readOnly
                        size={"small"}
                    />
                </Form.Item>
            </Col>
        </Row>
    )

    const handleInputFocus = (event: any) => event.target.select();

    const handleAmountChange = () => {
        const { cashAmount, bankAmount, creditNoteAmount } = form.getFieldsValue()
        const total = Number(cashAmount || 0) + Number(bankAmount || 0) + Number(creditNoteAmount || 0)
        setPaymentAmount(total)
    }

    const setFullPayment = (type: TabTypes) => {
        if (type === TabTypes.CASH) {
            form.setFieldsValue({
                cashAmount: props.currentData.currentBalance,
            })
        } else if (type === TabTypes.TRANS) {
            form.setFieldsValue({
                transAmount: props.currentData.currentBalance,
            })
        } else if (type === TabTypes.CREDIT_NOTE) {
            form.setFieldsValue({
                creditNoteAmount: props.currentData.currentBalance,
            })
        }

        handleAmountChange()
    }

    const setTodayPayment = (type: TabTypes) => {
        if (type === TabTypes.CASH) {
            form.setFieldsValue({
                cashDate: dayjs(),
            })
        } else if (type === TabTypes.TRANS) {
            form.setFieldsValue({
                transDate: dayjs(),
            })
        } else if (type === TabTypes.CREDIT_NOTE) {
            form.setFieldsValue({
                creditNoteDate: dayjs(),
            })
        }
    }


    const setNoneReference = (type: TabTypes) => {
        const noReference: string = "NA"

        if (type === TabTypes.CASH) {
            form.setFieldsValue({
                cashRef: noReference,
            })
        } else if (type === TabTypes.TRANS) {
            form.setFieldsValue({
                transRef: noReference,
            })
        } else if (type === TabTypes.CREDIT_NOTE) {
            form.setFieldsValue({
                creditNoteRef: noReference,
            })
        }
    }

    const renderCashboxInputs = () => (
        <Row gutter={[8, 0]} >
            <Col6>
                <Form.Item
                    label={
                        <LabelWithButton
                            text={descriptions.FORM.CASH.AMOUNT.LABEL}
                            buttonText="Completo"
                            onClick={() => setFullPayment(TabTypes.CASH)}
                        />
                    }
                    name={"cashAmount"}
                    rules={
                        selectedTab === "CASH"
                            ? [
                                {
                                    validator: (_, value) => {
                                        return amountValidator(value, true)
                                    }
                                },
                            ]
                            : undefined
                    }
                    validateTrigger="onBlur"
                >
                    <CurrencyInput
                        placeholder={descriptions.FORM.CASH.AMOUNT.PLACEHOLDER}
                        onChange={handleAmountChange}
                        onFocus={handleInputFocus}
                    />
                </Form.Item>
            </Col6>
            <Col6>
                <Form.Item
                    label={
                        <LabelWithButton
                            text={descriptions.FORM.CASH.DATE.LABEL}
                            buttonText="Hoy"
                            onClick={() => setTodayPayment(TabTypes.CASH)}
                        />
                    }
                    name={"cashDate"}
                >
                    <DatePicker
                        style={{ width: '100%' }}
                        format={SystemConstants.DATE_FORMAT}
                    />
                </Form.Item>
            </Col6>
            <Col12>
                <Form.Item
                    label={
                        <LabelWithButton
                            text={descriptions.FORM.CASH.REFERENCE.LABEL}
                            buttonText="NA"
                            onClick={() => setNoneReference(TabTypes.CASH)}
                        />
                    }
                    name={"cashRef"}
                >
                    <Input placeholder={descriptions.FORM.CASH.REFERENCE.PLACEHOLDER} />
                </Form.Item>
            </Col12>
        </Row>
    )

    const renderBankInputs = () => (
        <Row gutter={[8, 0]} >
            <Col6>
                <Form.Item
                    label={descriptions.FORM.TRANSACTION.AMOUNT.LABEL}
                    name={"bankAmount"}
                    rules={
                        selectedTab === TabTypes.TRANS
                            ? [
                                {
                                    validator: (_, value) => {
                                        return amountValidator(value, true)
                                    }
                                },
                            ]
                            : undefined
                    }
                    validateTrigger="onBlur"
                >
                    <CurrencyInput
                        placeholder={descriptions.FORM.TRANSACTION.AMOUNT.PLACEHOLDER}
                        onChange={handleAmountChange}
                        onFocus={handleInputFocus}
                    />
                </Form.Item>
            </Col6>
            <Col6>
                <Form.Item
                    label={descriptions.FORM.TRANSACTION.DATE.LABEL}
                    name={"bankDate"}
                >
                    <DatePicker
                        style={{ width: '100%' }}
                        format={SystemConstants.DATE_FORMAT}
                    />
                </Form.Item>
            </Col6>
            <Col6>
                <Form.Item
                    label={descriptions.FORM.TRANSACTION.BANK?.LABEL}
                    name={"bankBank"}
                >
                    <Input placeholder={descriptions.FORM.TRANSACTION.BANK?.PLACEHOLDER} />
                </Form.Item>
            </Col6>
            <Col6>
                <Form.Item
                    label={descriptions.FORM.TRANSACTION.REFERENCE.LABEL}
                    name={"bankRef"}
                >
                    <Input placeholder={descriptions.FORM.TRANSACTION.REFERENCE.PLACEHOLDER} />
                </Form.Item>
            </Col6>
        </Row>
    )

    const renderCreditNoteInputs = () => (
        <Row gutter={[8, 0]} >
            <Col6>
                <Form.Item
                    label={descriptions.FORM.CREDIT_NOTE.AMOUNT.LABEL}
                    name={"creditNoteAmount"}
                    rules={
                        selectedTab === TabTypes.CREDIT_NOTE
                            ? [
                                {
                                    validator: (_, value) => {
                                        return amountValidator(value, true)
                                    }
                                },
                            ]
                            : undefined
                    }
                    validateTrigger="onBlur"
                >
                    <CurrencyInput
                        placeholder={descriptions.FORM.CREDIT_NOTE.AMOUNT.PLACEHOLDER}
                        onChange={handleAmountChange}
                        onFocus={handleInputFocus}
                    />
                </Form.Item>
            </Col6>
            <Col6>
                <Form.Item
                    label={descriptions.FORM.TRANSACTION.DATE.LABEL}
                    name={"creditNoteDate"}
                >
                    <DatePicker
                        style={{ width: '100%' }}
                        format={SystemConstants.DATE_FORMAT}
                    />
                </Form.Item>
            </Col6>
            <Col12>
                <Form.Item
                    label={descriptions.FORM.CREDIT_NOTE.REFERENCE.LABEL}
                    name={"creditNoteRef"}
                >
                    <Input placeholder={descriptions.FORM.CREDIT_NOTE.REFERENCE.PLACEHOLDER} />
                </Form.Item>
            </Col12>
        </Row>
    )

    const handleTabChange = (value) => {
        setSelectedTab(value)
    }

    const renderForm = () => (
        <Form
            form={form}
            layout="vertical"
            requiredMark={false}
            autoComplete={'none'}
            size="small"
            onFinish={onFinish}
            disabled={props.readonly}
        >
            <Tabs
                defaultActiveKey="CASH"
                size={"small"}
                style={{ marginBottom: 32 }}
                onChange={handleTabChange}
                items={[
                    {
                        label: descriptions.TABS.CASH,
                        key: TabTypes.CASH,
                        children: renderCashboxInputs(),
                    },
                    {
                        label: descriptions.TABS.TRANSACTION,
                        key: TabTypes.TRANS,
                        children: renderBankInputs(),
                    },
                    {
                        label: descriptions.TABS.CREDIT_NOTE,
                        key: TabTypes.CREDIT_NOTE,
                        children: renderCreditNoteInputs(),
                    },
                ]
                }
            />
            {renderTotals()}
            {
                alertMessage.length > 0 &&
                <Alert
                    style={{ marginBottom: 12 }}
                    message={alertMessage}
                    type={Number(paymentAmount) > props.currentData.currentBalance ? "error" : "warning"}
                    showIcon
                />
            }
            {
                props.errorMessage &&
                <Alert
                    style={{ marginBottom: 12 }}
                    message={props.errorMessage}
                    type={"error"}
                    showIcon
                />
            }
            {!props.readonly && renderActionButtons()}
        </Form>
    )

    return (
        <>
            {renderData()}
            {renderForm()}
        </>
    )
}
